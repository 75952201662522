body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code, 
pre {
  display: block;
  margin: 5px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  background-color:beige;
  padding: 10px;
  border: 1px solid gray;
  color:darkslategrey !important;
}

p {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: normal;
  font-size: 1em;
  color: rgb(49, 2, 97);
}

a {
  text-decoration: none !important;
}
/* a:link {
  color: rgb(120, 120, 120);
} */
a:hover {
  color: rgb(120, 120, 120);
  text-decoration: none !important;
}
a:visited {
  /* color: rgb(120, 120, 120); */
  text-decoration: none !important;
}

textarea {
  resize: none;
}

.tiny {
  font-family: courier, courier new, serif;
  font-weight: lighter;
  font-size: 0.75em;
  color: #000000;
}
.tiny-green {
  font-family: courier, courier new, serif;
  font-weight: bolder;
  font-size: 0.90em;
  color: #006400;
}
.tiny-red {
  font-family: courier, courier new, serif;
  font-weight: bolder;
  font-size: 0.90em;
  color: #DC143C;
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.header-spacer {
  height: 90px;
  background-color: rgb(221, 217, 195);
}
.header-logo {
  /* vertical-align: top; */
  height: 90px;
  background-color: rgb(238, 238, 238);
}
.header-logo img {
  padding: 10px 0px 10px 50px;
}
.header-social {
  height: 90px;
  /* vertical-align: bottom; */
  background-color: rgb(238, 238, 238);
  /* background-color: lawngreen; */
}
.header-social img {
  width: 20px;
  height: 20px;
  /* border: 1px solid rgb(181, 181, 181); */
  margin-top: 60px;
  margin-left: 10px;
}
.header-bar {
  height: 30px;
  width: auto;
  background-color: rgb(181, 181, 181);
}

.nav {
  width: 100%;
  height: 100%;
  top: 0;
  position: sticky;
}
.nav-list {
  list-style-type: none;
}
.nav-list li {
  font-family: "Times New Roman", Times, serif;
  font-style: initial;
  font-size: large;
  padding: 20px 20px 5px 20px;
  margin-left: -50px;
}
.nav-list li:hover {
  background-color: rgb(196, 189, 151);
}


.bookmark {
  width: 100%;
  height: 100%;
  top: 0;
  position: sticky;
}
.bookmark-list {
  list-style-type: none;
}
.bookmark-list li {
  font-family: Arial, Helvetica, sans-serif;
  font-style: initial;
  font-size: medium;
  padding: 20px 20px 5px 20px;
  margin-left: -50px;
}

.content {
  background-color: rgb(238, 238, 238);
  height: auto;
}

.cmi-row {
  height: 175px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.cmi-title {
  font-family: "Times New Roman", Times, serif;
  font-size: 48px;
  font-style: italic;
  font-weight: bold;
  color:cadetblue;
}
/* .profile { background: url('./img/profile.png'); background-repeat: no-repeat}
.profile p { position: relative; left: 10px; padding: 3% 20% 3% 2%}
.consult { background: url('./img/consulting.png'); background-repeat: no-repeat}
.consult p { position: relative; left: 20px; padding: 3% 15% 3% 20% }
.contract { background: url('./img/contracting.png'); background-repeat: no-repeat}
.contract p { position: relative; left: 10px; padding: 4% 29% 4% 2% }
.product { background: url('./img/products.png');  background-repeat: no-repeat}
.product p { position: relative; left: 60px; padding: 4% 15% 4% 30% } */
.simple-bkgd { 
  background: url('./img/background2.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.svg-icon {
  display: inline-flex;
  align-self: center;
  margin-top: 2em;
}